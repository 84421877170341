import { Navigate } from "react-router-dom";
import DummyNavContent from "./DummyNavContent";
const BASE_PATH = "/documents/invoices";
export const dummyDashboard = () => [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/",
      element: <Navigate to={`documents`} />
    }
  },
  {
    target: "$ONE_LAYOUT_NAV_CONTENT",
    handler: {
      component: DummyNavContent,
      props: {
        basePath: `${BASE_PATH}`
      }
    }
  }
];
