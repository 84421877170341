import { loadable } from "../../utils/loadable";

const ErrorsList = loadable(() =>
  import(/* webpackChunkName: "ErrorsList" */ "./ErrorsList")
);

export const errorsView = [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/errors",
      element: <ErrorsList />
    }
  }
];
