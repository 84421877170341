import { useRef, useEffect } from "react";
import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetConfig } from "@onefront/react-sdk";

export const useTenantSwitcher = ({
  currentTenant = null,
  onTenantSwitch = () => {}
}) => {
  const tenantIdHeaderKey = useGetConfig("constants.headers.workspaceId");
  const { isLoading, tenant, error } = useCurrentTenant(true);

  const lastTenant = useRef(currentTenant ? currentTenant : tenant);

  useEffect(() => {
    if (tenant && tenant.id !== lastTenant?.current?.id) {
      lastTenant.current = tenant;
      onTenantSwitch();
    }
  }, [onTenantSwitch, tenant, tenant?.id]);

  return {
    tenant,
    isLoading,
    error,
    tenantIdHttpHeader: { [tenantIdHeaderKey]: tenant?.id }
  };
};
