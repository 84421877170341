import { Link } from "react-router-dom";

import Box from "@vapor/react-material/Box";
import List from "@vapor/react-material/List";
import ListItem from "@vapor/react-material/ListItem";
import ListItemIcon from "@vapor/react-material/ListItemIcon";
import ListItemText from "@vapor/react-material/ListItemText";

import Grid from "@vapor/react-icons/Grid";

const DummyNavContent = ({ basePath }) => {
  return (
    <Box role="presentation">
      <List>
        <ListItem button component={Link} to={`errors`}>
          <ListItemIcon>
            {/* placeholder until Laszlo/Enea provides icons */}
            <Grid color="inverse" />{" "}
          </ListItemIcon>
          <ListItemText primary={"Errors"} />
        </ListItem>
      </List>
    </Box>
  );
};

export default DummyNavContent;
